
//404 data

export const fourfour = {
    id: '404', 
    lightBg: true, 
    lightText: true, 
    lightTextDesc: true, 
    dark: true,
    primary: true, 
    darkText: false, 
};